<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) If you have
        <number-value :value="baseVol" unit="\text{mL}" />
        of a
        <number-value :value="baseConc" unit="\text{M}" />
        solution of ammonia,
        <chemical-latex content="NH3," />
        how many moles of
        <chemical-latex content="NH4Cl" />
        would you need to add to obtain a buffer with a
        <stemble-latex content="$\text{pH}$" />
        of
        <number-value :value="requiredpH" />
        . Assume that the addition of the
        <chemical-latex content="NH4Cl" />
        does not change the volume of the solution. Note that the
        <stemble-latex content="$\text{pK}_\text{b}$" />
        of
        <chemical-latex content="NH3" />
        is
        <stemble-latex content="$4.74.$" />
      </p>

      <calculation-input
        v-model="inputs.molNH4Cl"
        class="mb-5"
        prepend-text="$\ce{NH4Cl}:$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) What mass of
        <chemical-latex content="NH4Cl" />
        in grams, would need to be added to the original solution to obtain the desired
        <stemble-latex content="$\text{pH?}$" />
        Again, assume that the addition of the
        <chemical-latex content="NH4Cl" />
        does not change the volume of the solution.
      </p>

      <calculation-input
        v-model="inputs.massNH4Cl"
        prepend-text="$\text{Mass:}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question161',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        molNH4Cl: null,
        massNH4Cl: null,
      },
    };
  },
  computed: {
    baseConc() {
      return this.taskState.getValueBySymbol('baseConc').content;
    },
    baseVol() {
      return this.taskState.getValueBySymbol('baseVol').content;
    },
    requiredpH() {
      return this.taskState.getValueBySymbol('requiredpH').content;
    },
  },
};
</script>
